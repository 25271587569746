.unit-details {
    width: 100%;
    bottom:0;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    animation: unit-details .3s ease-in-out;
    padding: 10px 20px;
    position:absolute;
}
.unit-details-container {
    display:flex;
    height: 270px;
    gap:15px;
}
.unit-details h2 {
    color:rgb(99, 99, 99);
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2rem;
    width: fit-content;
    font-weight: 600;
    border-radius: 4px;
    padding: 0 2px;
}
.unit-details h2 .svg-inline--fa {
    font-size: .8rem;
    color:rgb(99, 99, 99);
}
.unit-details h2:hover {
    background-color: #e3e3e3;
    cursor: pointer;
}
.unit-details h2:hover .svg-inline--fa {
    color:rgb(61, 61, 61);
}

.unit-details h3 {
    color:rgb(149, 149, 149);
    margin:0 0 5px 0;
    display:flex;
    font-size: .9rem;
    font-weight: 600;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap:15px;
}
.unit-details-header {
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
}
.unit-details-header section {
    gap: 5px;
    margin: 5px 0;
}
.unit-details nav {
    display:flex;
    align-items: center;
    gap: 15px;
    margin: 2px 0 10px 0;
    border-bottom: 1px solid rgb(224, 224, 224);
}
.unit-details nav span {
    color: #989898;
    font-weight: 500;
    padding: 0 0 5px 0;
    font-size: .9rem;
    border-bottom: 3px solid rgba(0, 0, 139, 0);
}
.unit-details nav span:hover {
    color: #4f4f4f;
    cursor: pointer;
    border-bottom: 3px solid #719cc5;
}
.unit-details-selected {
    border-bottom: 3px solid #719cc5 !important;
}
.unit-details-header span {
    padding: 2px 8px;
    border-radius: 5px;
    background-color: rgb(34, 188, 0);
    color: #fafafa;
    font-size: .8rem;
    font-weight: 600;
    display:flex;
    align-items: center;
    gap:5px;
}
.unit-details-header span:hover {
    cursor: pointer;
    background-color: rgb(34, 188, 0, .8);
}

.not-gridlocked {
    background-color: #ff4040 !important
}
.available {
    background-color: rgb(34, 188, 0);
}
.not-available {
    background-color: rgb(255, 156, 75) !important;
}
.unit-details-section {
    width: 40%;
}
.header-pills {
    display:inline-flex
}
.unit-details-pills{
    background-color: rgb(244, 244, 244);
    padding: 10px 15px;
    border-radius: 10px;
}
.unit-details-pills button {
    outline: none;
    border:  none;
    color: #b7b7b7;
    font-weight: 500;
    font-size: .8rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 3px 4px;
}
.unit-details-pills button:hover {
    cursor: pointer;
    color: #555555;
    background-color: #b7b7b72d;
}
.unit-details-pills:first-of-type {
    margin-bottom: 5px;
}
.absolute-view-button {
    position:absolute;
    right: 0;
    top:0;
    border-radius: 5px;
    outline: none;
    border:none;
    z-index: 1500;
    color: #fafafa;
    font-weight: 600;
    padding: 5px 10px;
    margin:5px;
    background-color: #738abbd0;
}
.absolute-view-button:hover {
    background-color: #7395e0;
}
.absolute-view-button:focus{
    background-color: #6e96ec;
}
.unit-details-map {
    position: relative;
    width: 65%;
    height: 100%;
    border-radius: 10px;
}
.unit-details-section .svg-inline--fa {
    font-size: .8rem;
    color:rgb(94, 94, 94);
}

.unit-details-pills span {
    display:inline-block;
    white-space: nowrap;
    cursor: pointer;
    color:rgb(255, 254, 254);
    margin:0px 4px 2px 0;
    gap:7px;
    align-items: center;
    font-size: .8rem;
    width: fit-content;
    padding: 2px 6px;
    border-radius: 5px;
    font-weight: 600;
    background-color: #13286270;
}
.unit-details-pills span .svg-inline--fa {
    margin-right: 6px;
    color:#fafafa;
    font-size: .8rem;
}
.unit-details-pills span:hover {
    background-color: #132862a4;
}


@keyframes unit-details {
    from {
        height: 0%;
        opacity: 0;
    }
    to {
        height: 50%;
        opacity: 1;
    }
    
}


