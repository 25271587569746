:root {
    --border-color: 1px solid rgb(168, 206, 255);
  }

.form-group {
  position: relative;
}
.form-group input {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid rgba(0,0,0,.37);
  border-radius: 4px;
  background-color: transparent;
  margin: 0px auto;
  padding: 6px 6px 4px 8px;
  outline: none !important;
  color: rgba(0,0,0,0.85);
  transition: all .2s ease-in-out;
}

.form-group label {
  position: absolute;
  top: 7px; 
  text-align: left;
  display: inline-block;
  height: 14px;
  line-height: 14px;
  padding: 0 2px;
  font-size: 11px;
  font-weight: 400;
  background: #fff;
  color: #5f5f5f;
  margin: 0px 10px;
  cursor: text;
  font-weight: 600;
  transition: all .15s ease-in-out;
}
.form-group input:hover, .form-group input:focus { 
    border: 1px solid #64a5ff;
    background-color: aliceblue;
}

.form-group input:valid + label, .form-group input:focus + label { 
  top: -6px;
  color: #64a5ff;

  font-weight: bold;
}
.dropdown-content {
    width:100%;
    background-color: rgb(255, 255, 255);
    position:absolute;
    max-height: 300px;
    overflow-y: scroll;
    border-bottom: var(--border-color);
    border-left:var(--border-color);
    border-right:var(--border-color);
    margin:0;
    display:flex;
    flex-direction: column;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

}
.dropdown-content span {
    font-size: .7rem;
    padding: 2px 5px;
    color: #515151;
}
.dropdown-content span:hover {
    cursor: pointer;
    background-color: rgb(218, 237, 255);
}
.dropdown-item {
    position:relative;
    z-index: 1000;
}
.dropdown-caret{
    position:absolute;
    right: 10px;
    top:6px;
    font-size: .8rem !important;
}
.dropdown-item input {
    padding: 5px;
    font-size: .7rem;
    border-radius: 3px;
    border:1px solid rgb(189, 189, 189);
    outline: none;
    height: 27px;
    max-width: 120px;
    z-index: 950;
}
.dropdown-item input:focus {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: var(--border-color);
    border-left:var(--border-color);
    border-right:var(--border-color);
    outline: none;
    border-bottom:none;
}

.dropdown-item input:focus + .dropdown-caret {
    display: none !important;

}