
.hex-container{
  position: absolute;
  overflow: hidden;
  height: 100vh;
  animation: animate .5s linear forwards;
}
@keyframes animate{
  0%{
    transform: scale(1.2);
    opacity: 0;
  }
  80%{
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
    opacity: .5;
  }
}
.row{
  display: inline-flex;
  margin-left: -50px;
  margin-top: -32px;
  overflow: hidden;
}
.row:nth-child(even){
  margin-left: 1px;
}
.hexagon{
  position: relative;
  height: 110px;
  width: 93px;
  background: #e8e8e85e;
  margin: 5px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: 2s;
}
.hexagon:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.36);
}
.hexagon:hover{
  transition: 0s;
  background: rgb(255, 255, 255);
  cursor: pointer;
}