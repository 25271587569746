.unit-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #b7b7b752;
    overflow: scroll;
}
.unit-list table {
    margin-top:15px;
    width: 95%;
    background-color: #fafafa;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px rgba(51, 51, 51, 0.1);
}
.unit-list table thead {
    border-bottom: 2px solid #d8d8d8;
}
.unit-list table thead tr th {
    padding: 10px 15px;
    font-size: .9rem;
    font-weight: 800;
    color: #8d8d8d;
    text-align: left;
}
.unit-list table tbody tr td {
    padding: 10px 15px;
    font-size: .9rem;
    font-weight: 500;
    color: #8f8f8f;
    text-align: left;
}
.unit-list table tbody tr:hover {
    background-color: #d5e1ffb5;
    cursor: pointer;
}
.unit-list table tbody tr:hover td {
    color: #464646;
}
.unit-list table tbody tr {
border-bottom: 1px inset #dddddd87;
}
.unit-list-details {
    width: 100%;
    position:absolute;
    height: calc( 100% - 85px );
    display: flex;

}
.unit-list-details-exit {
    width: 50%;;
}
.unit-list-details-content {
    width: 50%;
    min-width: 400px;
    border-top: 4px solid #53a9ff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 12px 15px 3px rgba(51, 51, 51, 0.274);
}
.unit-list-details-content .unit-details-container{
    flex-direction: column;

}
.unit-list-details-content .unit-details-section{
    width: 100%;
}
.unit-list-details-content .unit-details-container {
    height: 600px !important
}
.unit-list-details-content .unit-details-map {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.unit-list-details-content .unit-details {
    position:relative !important
}