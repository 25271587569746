
.home-box {
    background-image: linear-gradient( 174.2deg,  rgb(237, 246, 255) 7.1%, rgba(240,246,238,1) 67.4% );
    height: auto;
    padding: 0 60px;
    display:flex;
    flex-direction: column;
    gap: 15px;

}
.home-box header{
    padding: 30px 0 10px 0;
}
.home-box header h4 {
    margin:0 0 10px 0;
    font-size: 1rem;
    color:grey;
    font-weight: 400;
}
.home-box header h2 {
    margin:0;
    color:#323232;
    font-size: 1.2;
    font-weight: 600;
}
.home-box section {
    width:100%;
    background-color: rgba(248, 252, 255, 0.8);
    border-radius: 20px;
    padding: 25px;
    box-shadow: 0px 0px 4px 0px rgba(35, 35, 35, 0.1);
}
.home-info-box{
    display:flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid rgba(221, 221, 221, 0.498);
    border-radius: 10px;
    width: 49%;
}
.home-box section h2 {
    margin:0 0 20px 0;
    color:rgb(77, 77, 77);
    font-size: 1.1rem;
    font-weight: 600;
}
.home-info-box h3 {
    margin:0 0 10px 0;
    color:rgb(91, 91, 91);
    font-size: 1.1rem;
    font-weight: 500;
    display:flex;
    gap: 10px;
    align-items: center;
}
.home-info-box h3 .svg-inline--fa {
    font-size: .9rem;
    color:rgb(140, 140, 140);
}
.home-info-box span {
    display:flex;
    align-items: flex-end ;
    gap:5px;
}
.pie-chart-container {
    width:100%;
    height: 250px;
}

.home-info-box:hover {
    cursor: pointer;
    background-color: #b4b4b404;
}
.home-info-box p {
    margin:0;
    color:rgb(77, 77, 77);
    font-size: .9rem;
    font-weight: 500;
}
.home-info-box h1 {
    margin:0;
    color:rgb(50, 50, 50);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5rem;
}
.home-info-container {
    display:flex;
    flex-direction: row;
    justify-content: space-between;

}

.home-info-mini-box {
    width: 100% !important;
    box-shadow: 0px 0px 10px 0px rgba(35, 35, 35, 0.12);
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 10px !important; 
    
}
.home-info-mini-box h1 {
    color:#4f4f4f 
}
.home-info-mini-box p {
    color:#797979;
    text-align:center;
}
.green {
    background-color: #c8e9ff;
    background-image: linear-gradient(135deg, #d8ffdc 0%, #a5e7b5 100%);
}
.yellow {
    background-color: #fff9c8;
    background-image: linear-gradient(135deg, #fff0bb 0%, #faee9a 100%);
}

.home-graph-details {
    width:70%;
    height: 400px;
}
.graph-container {
    width: 100%;
    height: 350px;
}


.graph-divider{
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.welcome{
    text-align: center;
}