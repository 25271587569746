.admin-main {
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.container{
    width: calc(100% - 170px);
    position:relative;
}
.overflow-scroll {
    overflow: scroll;
}

@keyframes unit-chart-zoom-in {
    0% {
        transform: scale(1.2);
        opacity: 0;
    }
    70% {
        transform: scale(.8);
        opacity: .2;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}