@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
* {
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  height:100vh
}
#root {
  height: 100%;
}