:root {
    --facet-border-color: 1px solid rgb(213, 213, 213);
}

.facet-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-bottom: var(--facet-border-color);
}
.facet-item span {
    display:flex;
    gap:10px;
    align-items: center;
    flex-direction: row;
    padding: 7px 15px;
    border-radius: 4px;
}
.facet-content {
    padding: 5px 10px;
    background-color: #fbfbfb;
}
.facet-item h3 {
    margin: 0;
    font-size: .8rem;
    font-weight: 600;
    color:rgb(70, 70, 70);
}
.facet-item .svg-inline--fa {
    font-size: .8rem;
    color:rgb(70, 70, 70);
}
.facet-item span:hover {
    cursor: pointer;
    background-color: rgb(227, 242, 255);
}
.facet-item span:active {
    background-color: rgb(207, 233, 255);
}
.facet-choice {
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 0;
    background-color: #fbfbfb;
    gap:7px;
}
.facet-choice input {
    width: 17px;
    height: 17px;
    border-radius: 2px;
    outline:none;
    cursor: pointer;
}
.facet-choice p {
    margin:0;
    font-size: .8rem;
    
}
