.settings-data-container {
    width: 100%;
    height: calc(100% - 46px);
}

.settings-data-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    width: 100%;
    border-bottom: 1px inset #dedede56;
}
.settings-data-row div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
}
.settings-data-row label {
    margin-right: 10px;
    font-weight: 400;
    font-size: .9rem;
    text-align: left;
    color:rgba(18, 47, 72, 0.587);
}
.settings-data-row span {
    padding: 0 10px;
}
.settings-data-row input {
    height: 30px;
    width: 100px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    font-size: 1rem;
    outline: none;
}
.settings-data-row p {
    margin: 0 0 0 10px;
    font-size: .8rem;
    font-weight: 500;
    color: #666;
}
.settings-data-row h6 {
    margin: 0 0 0 10px;
    font-size: .7rem;
    font-weight: 400;
    color: #666;
}
.settings-data-container nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px inset #dedede7c;
}
.settings-data-container form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    background-color: rgb(253, 254, 255);
    margin: 30px;
    border-radius: 20px;
    box-shadow: 0 0 10px #ccc;
}
.settings-data-container form button {
    height: 35px;
    border-radius: 5px;
    border: none;
    background-color: rgba(18, 47, 72, 0.781);
    color: #fafafa;
    font-size: .8rem;
    font-weight: 400;
    margin: 15px 0 0 0 ;
    outline: none;
    padding: 0 15px;
    align-self: flex-end;
}
.settings-data-container form button:hover {
    cursor: pointer;
    background-color: rgba(18, 47, 72, 0.587);
}
.settings-data-container nav span {
    font-size: .8rem;
    font-weight: 500;
    padding: 8px 20px 6px 20px;
    color: #666;
}
.settings-data-container nav span:hover {
    cursor: pointer;
    background-color: rgba(18, 47, 72, 0.587);
    color:#fafafa;
}
.settings-data-container nav span.active {
    background-color: rgba(18, 47, 72, 0.781);
    color:#fafafa;
}
.account-settings {
    width:100%;
    height: calc(100% - 35px);
    display: flex;
}