@keyframes loginEnter {
    from {
        opacity: 0;
        transform: translateY(30%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes errorAnimation {
    from {
        opacity: 0;
        transform: translateY(30%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes loaderEnter {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
    
.login-container {
    width: 40%;
    min-width: 300px;
    max-width: 550px;
    background-image: linear-gradient(180deg, #ffd7d7 0%, #ffffff 30%, #ffffff 80%, #d4d4d4 100%);
    z-index: 1000;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 2px 2px 10px 2px rgba(56, 56, 56, 0.297);
    animation: loginEnter .3s ease-in-out;
}
.signup-buttons {
    width: 100%;
    padding: 15px 20px 15px 20px;
    display:flex;
    flex-direction: row;
    background-image: linear-gradient(180deg,#b1b1b1c3 0%, #e2e2e200 100%);
    border-top: 1px inset #99999913;
    justify-content: space-between;
}
.login-logo  {
    width: 40%;
    min-width: 200px;
    margin:20px;
}
.login-background {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(0deg, #8b9faa 0%, #ae8b9c 100%);
}
.login-background form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;

}
.login-background .form-group {
    width: 100%;
}
.login-background input {
    height: 50px;
    border: 1px solid rgb(224, 224, 224);
    font-size: 1rem;
}
.login-background input:hover + label {
    background-color: aliceblue
}
.login-background label {
    font-size: .8rem;
    border-radius: 2px;
}
.login-background label:focus {
    background-color: aliceblue
}
.error-message {
    width: 90%;
    border-radius: 15px;
    background-color: rgba(255, 0, 0, 0.31);
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 15px;
    margin: 0 15px 20px 15px;
    padding: 8px 10px;
    animation: errorAnimation .3s ease-in-out;
}
.login-container .socket {
    height: 200px;
    position: relative;
    margin: 0;
    animation: loaderEnter .5s ease-in-out;
}

.error-message .svg-inline--fa {
    font-size: 1.7rem;
    color:rgb(175, 0, 0);
}
.error-message p {
    margin: 0 !important;
    color:rgb(175, 0, 0) !important;

}
.login-container button {
    height: 50px;
    width: calc(100% - 40px);
    border: none;
    background-image: linear-gradient(180deg, #dfdfdf15 0%,#d2d2d22e 100%);
    color: #41414193;
    font-size: .9rem;
    font-weight: 700;
    border-radius: 5px;
    padding: 0px 15px;
    cursor: pointer;
}
.signup-buttons button {
    width: fit-content;
    display:flex;
    align-items: center;
    justify-content: center;
}
.login-container button .svg-inline--fa {
    font-size: 1rem;
    margin: 0 10px;
}
.login-container button:hover {
    background-color: #d2d2d2;
    cursor: pointer;
    color: #303030cc;
}
.login-container .form-group{
    padding: 2px 20px;
}
.login-container a {
    margin: 20px 0 20px 0;
    font-size: .8rem;
    font-weight: 500;
    cursor: pointer;
    color: rgb(73, 88, 255);
}
.login-container a:hover {
    text-decoration: underline;
    color: rgb(0, 15, 180);
}
.login-container p {
    font-size: .9rem;
    font-weight: 600;
    color: #7a7a7a;
    margin: 0 0 10px 0;
}
.google-option {
    background-color: rgb(101, 150, 255);
    color:#fafafa !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.google-option:hover { 
    background-color: rgb(47, 104, 227) !important;
    color:#fafafa !important;
}
.google-option img{
    width: 35px !important;
    margin: 0 10px 0 0;
    background-color: #fafafa;
    padding: 3px;
    border-radius: 3px;
}