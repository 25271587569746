.welcome-container {
    display:flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #b7cbd6 0%, #c5b3c4 100%);
}
.welcome-popup-container {
    animation: welcome-popup .5s ease-in-out;
    width: 85%;
    height: 80%;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, .3);
}
.welcome-popup-content h2 {
    font-size: 1.4rem;
    color: #5c5c5cfa;
    font-weight: 600;
    margin: 10px 0 0 10px;
}
.welcome-popup-content h3 {
    font-size: .8rem;
    color: #5c5c5ca5;
    font-weight: 400;
    margin: 0 0 20px 10px;

}
.welcome-popup-side-tabs {
    min-width: 100px;
    width: 18%;
    height: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image: linear-gradient(0deg, #8b8b8b7a 0%, #bdbdbd38 100%);
}
.welcome-popup-side-tabs div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: .8rem;
    color: #5c5c5cfa;
    cursor: pointer;
    font-weight: 600;
    cursor: pointer;
    padding: 25px 8px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.welcome-popup-content {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    gap: 10px;
    padding: 20px;
}
.popup-arrow {
    display: none;
    font-size: 1rem;
}
.welcome-popup-side-tabs div .svg-inline--fa {
    width: 40px;
}
.welcome-popup-side-tabs div:hover{
    background-color: #464f59da;
    color: #fafafa;
}
.welcome-popup-side-tabs div:active{
    background-color: #505f6dda;
    color: #cdcdcd;
}
.welcome-popup-side-tabs div:hover .popup-arrow {
    display: block;
    animation: arrowfadein .3s ease-in-out;
}
.welcome-popup-active{
    background-color: #2d343bda !important;
    color: #fafafa !important;
}
.welcome-popup-active .popup-arrow {
    display: block;
    animation: arrowfadein .3s ease-in-out;
}
.welcome-popup-inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
.welcome-popup-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgb(244, 244, 244);
    padding: 20px 15px;
    border-radius: 5px;
}
.input-next-button {
    position:absolute;
    bottom: 10px;
    right: 10px;
    outline: none;
    border: none;
    background-color: #007d0caa;
    color: #c6ffcc;
    font-size: 1rem;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.input-next-button:hover {
    background-color: #007d0c;
    color: #fafafa;
}
.welcome-popup-input span {
    display:flex;
    width: 40%;
    min-width: 200px;
    flex-direction: column;
}
.welcome-popup-input .input {
    width: 200px !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

}
.welcome-popup-input .input p {
    margin: 0 10px 0 0;
    font-size: .7rem;
    color: #5c5c5cfa;
    font-weight: 500;
}
.welcome-popup-input label {
    font-size: 1rem;
    color: #5c5c5cfa;
    font-weight: 600;
    margin:0;
}
.welcome-popup-input input {
    width: 100px;
    height: 40px;
    border: 1px solid #b7b7b700;
    border-radius: 5px;
    padding: 0 10px;
    outline: none;
    font-size: .9rem;
    background-color: #2d343bda;
    color: #fffffffa;
    font-weight: 600;
}
.welcome-popup-input input:focus {
    outline: none;
    border: 1px solid #5c5c5c1d;
}
.welcome-popup-input input::placeholder {
    color: #5c5c5cfa;
    font-weight: 600;
}
.welcome-popup-input input:hover {
    border: 1px solid #5c5c5c34;
}
.welcome-popup-input input:active {
    border: 1px solid #5c5c5cfa;
}
.welcome-popup-input h6 {
    font-size: .7rem;
    color: #5c5c5cfa;
    font-weight: 400;
    width: fit-content;
    margin:0;
    display:flex;
    align-items: flex-start;

}
.welcome-popup-input h6 .svg-inline--fa {
    margin: 3px 7px 0 0;
}
@keyframes arrowfadein {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }    
}
@keyframes welcome-popup {
    0% {
        transform: scale(.9);
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        transform: scale(1);
        opacity: 1;
    }
}