.activity-container {
    width: 100%;
    height: calc(100% - 48px - 50px)
}
.bar-graph-container {
    width: 100%;
    height: 15%;
    margin-top:50px;
}
.activity-list-container {
    width: 100%;
    height: calc(100% - 135px);
    display:flex;
    flex-direction: row;
    box-shadow: inset 0px 0px 5px 0px rgba(35, 35, 35, 0.22);
}
.activity-list {
    display:flex;
    width: calc(100% - 200px);
    overflow-y: scroll;
    flex-direction: column;
    padding: 0px 0 0 10px;
}
.side-search {
    width: 200px;
    padding: 0px 0px;
    overflow-y: scroll;
    height: 100%;
    box-shadow: 0 7px 7px 2px #00000039;
    background-color: rgb(255, 255, 255);
    
}
.activity-event {
    width:100%;
    border-bottom: 2px solid #ababab;
    position: relative;
}
.activity-list-header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2px 15px;
    border-bottom: 1px solid rgb(231, 231, 231);
}
.activity-list-header p{
    margin:0;
    font-size: .8rem;
    font-weight: 600;
    color:rgb(109, 109, 109);
}
.activity-list-header button {
    border:none;
    background-color: transparent;
    outline:none;
    cursor: pointer;
    color:rgb(140, 140, 140);
    font-size: .8rem;
    font-weight: 600;
    display:flex;
    padding: 7px 15px;
    align-items: center;
    border:1px solid rgb(238, 238, 238);
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(35, 35, 35, 0.1);
    gap: 5px;
}
.activity-list-header button:hover {
    color:rgb(255, 255, 255);
    background-color: rgb(107, 131, 255);
}
.activity-list th {
    padding: 10px;
    font-size: .9rem;
    font-weight: 800;
    color: #8d8d8d;
    text-align: left;
}
.activity-list td {
    padding: 10px;
    font-size: .8rem;
    font-weight: 500;
    color: #8f8f8f;
    text-align: left;
}
.activity-list td span {
    font-size: .9rem;
    font-weight: 500;
    display:flex;
    align-items: center;
    color: #8f8f8f;
    text-align: left;
}
.activity-list td span .svg-inline--fa{
    font-size: 1.1rem;
    padding: 7px;
    border-radius: 4px;
}
.activity-list table {

    border-collapse: collapse;
    border-spacing: 0;
}

.activity-list tr {
    border-bottom: 1px solid #d8d8d856;
}
.activity-list tr:hover {
    background-color: #ebf4ff;
    cursor: pointer;
}
.activity-list tr:active {
    background-color: #d9e9ff;
}
.lockIcon {
    color: rgb(113, 198, 113);
}
.unlockIcon {
    color: rgb(241, 215, 71);
}
.newUserIcon{
    color: rgb(119, 119, 219);
}
.lowBatteryIcon{
    color: rgba(255, 119, 0, 0.657);
}
.activity-list-message-header {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding:0px;
    gap: 6px;
}
.activity-list-message-header .status-pill {
    font-size: .7rem;
    font-weight: 600;
    background-color: rgba(0, 118, 0, 0.259);
    color: rgb(0, 140, 0);
    padding: 3px 5px;
    border-radius: 4px;
}
.user-status {
    background-color: rgba(87, 87, 255, 0.254) !important;
    color:rgb(92, 92, 247) !important;

}
.activity-list-message-header p {
    margin:0;
    font-size: .7rem;
    font-weight: 600;
    color:rgb(162, 162, 162);
}
.activity-list-message-body p {
    margin:10px 0px;
    font-size: .9rem;
    font-weight: 600;

    color:rgb(77, 77, 77);
}
.activity-list-message-body h3 {
    margin:0;
    font-size: .9rem;
    font-weight: 600;
    display: flex;
    color:rgb(77, 77, 77);
}
.action-th, .date-th {
    width: 160px;
}
.activity-list-message-body a {
    margin:0 10px 0 0;
    font-size: .7rem;
    font-weight: 500;
    text-decoration: none;
}