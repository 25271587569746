.pin-object {
    padding: 3px 4px 0px 5px;
    text-align: center;
    background-color: #ff4040;
    border-radius: 40%;
    font-size: 1rem;
    color:#fafafa;
    font-weight: 600;
    display:flex;
    flex-direction: column;
}
.map-logo {
    width:30px;
    z-index: 500;
}
.bottom-arrow {
    position: absolute;
    width: 21px;
    height: 21px;
    bottom: -5px;
    background-color:#ff4040;
    left: 23%;
    z-index: 29;
    transform: rotate(45deg);
}
.pin-container {
}
.single-lock {
    padding: 6px 5px 0px 4px;
}
.single-lock .bottom-arrow{
    bottom: -6px;
    left: 24%;
}
.single-lock .map-logo {
    padding: 0 0 7px 0;
    width:30px;
}