:root {
    /* Change this to change the appearance of the hexaons */
    
    /* Other hexagon dimentions */
    --hex-transition: all .2s ease;
    
    /* Colors */
    --color-hex-default: #606e7c;
    --color-hex-hover:   #719cc5;
    --color-hex-selected: #719cc5;
  }
  


.hexagon-container {
  width: 100%;
  position:relative;
  padding:20px;
  overflow: scroll;
  height: 100%;
  font-size: 0; /*disable white space between inline block element */
}

.hexagon-container div {
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s)*1.1547); 
  display: inline-block;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  background: #56626e;
  font-size:initial;
  margin-bottom: calc(var(--m) - var(--s)*0.2885); 
  position:relative;
  cursor: pointer;

}
.hexagon-container div:hover, .hexagon-container div:hover::before {
  background: var(--color-hex-hover);
}
.hexagon-container div::before{
  position:absolute;
  content:"";
  width:50%;
  height:100%;
  background: #606e7c;

}
.hexagon-container p {
  position:absolute;
  color:#fafafa;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: .7rem;
}
.hexagon-container::before {
  content: "";
  width: calc(var(--s)/2 + var(--m));
  float: left;
  height: 120%;
  shape-outside: repeating-linear-gradient(#0000 0 calc(var(--f) - 3px),#000 0 var(--f));

}


.square:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(32, 32, 32);
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.1s;
}

.zoomed {
    background-color: var(--color-hex-selected) !important;
    border-top: 1px solid var(--color-hex-selected) !important;
    border-bottom: 1px solid var(--color-hex-selected) !important;
}
.zoomed::after, .zoomed::before {
  background-color: var(--color-hex-selected) !important;
    border-top-color: var(--color-hex-selected)  !important;
    border-bottom-color: var(--color-hex-selected)  !important;
}
.zoomed:hover {
    
}
.square span {
    padding:4px 10px;
    background-color: #4f4f4f87;
    border-radius: 3px;
}
.square p {
    color: #fafafa;
    margin: 0;
    font-size: .5rem;
    font-weight: 600;
}
.unit-chart-box {
    background-image: linear-gradient( 174.2deg,  #ffffff 7.1%, #edf6ff 67.4% );
    width: 100%;
    height: calc(100% - 88px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    animation: unit-chart-zoom-in .3s ease-in;
    --s: 40px;  /* size  */
    --m: 1px;    /* margin */
    --f: calc(1.732 * var(--s) + 4 * var(--m)  - 1px);
}
.unit-chart::-webkit-scrollbar {
        display: none;
      }
.unit-chart {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
      /* Hexagons */
      .hexagon-container::-webkit-scrollbar {
        display: none;
      }
      .hexagon-container {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
      