
.battery0 {
    background-color: #ab0000 !important;
}
.battery2 {
    background-color: #ff4040 !important;
}
.battery3 {
    background-color: rgb(244, 206, 39) !important;
}
.battery4 {
    background-color: rgb(34, 188, 0) !important;
}
.battery100 {
    background-color: rgb(34, 188, 0) !important;
}
.battery-icon {
    font-size: 1.5rem;
}

.battery0, .battery2, .battery3, .battery4, .battery100 {
    padding: 2px 8px;
    border-radius: 5px;
    background-color: rgb(34, 188, 0);
    color: #fafafa !important;
    font-size: .8rem !important;
    font-weight: 600 !important;
    display: flex;
    width: fit-content;
    min-width: 70px;
    align-items: center;
    gap: 8px !important;
    justify-content: center;
}
.battery0 .svg-inline--fa, .battery2 .svg-inline--fa, .battery3 .svg-inline--fa, .battery4 .svg-inline--fa, .battery100 .svg-inline--fa {
    font-size: 1.3rem;
    width: 30px;
    background-color: transparent!important;
    box-shadow: none!important;
    padding: 0px !important;
    border-radius: 4px;
}