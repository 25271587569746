.chart-container {
}
.widget{

}

.widget nav {
    display:flex;
    align-items: center;
    gap: 15px;
    margin:0;
    width:100%;
    justify-content: space-evenly;
}
.widget nav h2 {
    margin:0;

}

.widget nav button {
    border:none;
    background-color: transparent;
    outline:none;
    cursor: pointer;
    color:rgb(140, 140, 140);
    font-size: .9rem;
    font-weight: 600;
    display:flex;
    padding: 10px 15px;
    align-items: center;
    border:1px solid rgb(238, 238, 238);
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(35, 35, 35, 0.12);
    gap: 5px;
}
.widget nav button:hover {
    color:rgb(255, 255, 255);
    background-color: rgb(175, 183, 255);
}
.widget nav .active {
    color:rgb(255, 255, 255);
    background-color: rgb(122, 135, 255);
}
