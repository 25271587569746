.side-menu {
    background: linear-gradient(0deg, #606e7c 0%, #2d343b 100%);    display:flex;
    flex-direction: column;
    padding:0px;
    width: 170px;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.274);
}
.side-menu img {
    width: 150px;
    margin: 20px 0px 20px 0;
    display:flex;
    justify-self: center;
}
.side-menu section {
    width: 100%;
    margin:0;
    padding:0;
}
.side-menu-active {
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5), inset -20px 0px 10px -19px #ff4040;
    border-right:3px solid #ff4040 !important;
}
.side-menu .side-menu-split {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.side-menu-split p {
    font-size: .7rem;
    color: rgb(189, 189, 189);
    text-align: center;
    margin:0;
}
.side-menu-split p:last-of-type {
    margin-bottom: 15px;
    color: rgb(192, 192, 192);
    font-weight: 600;
}

.side-menu div {
    width: 100%;
    padding: 18px 15px 18px 12px;
    font-size: .7rem;
    font-weight: 600;
    color:rgb(238, 238, 238);
    border: inset 1px rgba(125, 125, 125, 0.219);
}
.side-menu div:hover {
    background-color: rgba(19, 19, 19, 0.373);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.side-menu div:hover .svg-inline--fa{
    color:rgb(255, 255, 255);
}
.side-menu .svg-inline--fa{
    color:rgb(238, 238, 238);
    font-size: 0.9rem;
    margin-right: 15px;
    width: 40px;
}