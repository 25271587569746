:root {
  --gray-border-color: rgba(219, 219, 219, 0.577);
}

.gl-form-asterisk {
    background-color: inherit;
    color: #e32b2b;
    padding: 0;
    padding-left: 3px;
   
}
.gl-form-asterisk:after {
    content: "*";
}




.admin-page-header {
    width:100%;
    background-color: rgba(255, 255, 255);
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .1);
    justify-content: flex-start;
    padding: 10px 10px 10px 20px;
    z-index: 1000;
    align-items: flex-start;
    gap:8px;
    position: relative;
    border-bottom: 1px inset #99999956;
}
.lock-bar-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.admin-page-header section{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.admin-page-header .svg-inline--fa {
    font-size: 1.2rem;
    color: rgb(149, 149, 149);
    margin:0;
}
.admin-page-header-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.admin-page-header h1 {
    font-size: 1.1rem;
    color: rgb(149, 149, 149);
    margin: 0;
    font-weight: 600;
}
.filter-container {
    display:flex;
    align-items: center;
    gap:5px;
}
.fill-by {
    position:absolute;
    right:10px;
}
.fill-by select {
    width: auto !important;
    border: 2px solid #9ebcda;
}
.grid-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    gap:0;
    padding: 0;
}
.grid-list button {
    background-color: rgb(224, 224, 224);
    outline: none;
    cursor: pointer;
    padding: 5px 8px;
    font-weight: 700;
    margin: 0;
    font-size:.8rem;
    border:none;
    color: #7a7a7a61;
}
.grid-list button:hover {
  background-color: rgb(163, 163, 163);
  color: #ffffff;
}
.grid-list-selected {
    background-color: #719cc5 !important;
  color: #ffffff !important;
}
#grid {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: 2px solid var(--gray-border-color);
  border-top: 2px solid var(--gray-border-color);
  border-bottom: 2px solid var(--gray-border-color);
}
#list {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 2px solid var(--gray-border-color);
    border-top: 2px solid var(--gray-border-color);
    border-bottom: 2px solid var(--gray-border-color);
}
.time-container {
    min-width: 250px;
    width: 15%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    position: relative;
}
.select-false {
    border: 1px solid rgb(209, 209, 209);
}
.select-true .time-display{
    border-top: 1px solid rgb(209, 209, 209);
    border-left: 1px solid rgb(209, 209, 209);
    border-right: 1px solid rgb(209, 209, 209);
    border-bottom: 1px  solid rgba(209, 209, 209, 0.519);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #eaf5ff;
}
.select-true .time-options {
    border-bottom: 1px solid rgb(209, 209, 209);
    border-left: 1px solid rgb(209, 209, 209);
    border-right: 1px solid rgb(209, 209, 209);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
.select-false:hover{
    cursor: pointer;
    border: 1px solid rgb(85, 85, 85);
    box-shadow: 0px 0px 3px 1px rgba(49, 49, 49, 0.4);
}
.select-false {
    border: 1px solid rgb(209, 209, 209);
    box-shadow: none;
}
.time-display, .time-option {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px;
}
.time-options {
    z-index: 1;
    top: 30px;
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    position:absolute;
}

.time-option:hover {
    cursor: pointer;
    background-color: #7ab6ff;
    color: #ffffff;
}
.time-option:hover p {
    color: #ffffff;
}
.time-display span, .time-option span {
    font-size: .7rem;
    font-weight: 500;
    color: #595959;
    margin: 0;
    background-color: #e8e8e8;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 2px 0;
    justify-content: center;
    width: 50px;
}
.time-display p, .time-option p {
    font-size: .7rem;
    font-weight: 500;
    color: #3b3b3b;
    margin: 0 5px;
}
.time-caret {
    position: absolute;
    right: 10px;
    font-size: .8rem !important;
    cursor: pointer;
}
.live {
    background-color: #04d600 !important;
    color: #ffffff !important;
}
.blinking {
    animation: blinker .7s ease-in-out infinite;
}
@keyframes blinker {
    50% {
      opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}